import BaseBean from "@/utils/BaseBean";

export interface IClientRepairVideosDataObj {
    utilInst:ClientRepairVideosUtil
    refMap:Map<string,any>
    otherParams:any
}
export default class ClientRepairVideosUtil extends BaseBean{
    public dataObj:IClientRepairVideosDataObj;

    constructor(proxy:any,dataObj:IClientRepairVideosDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //加载视频
    public async clientQueryVideo():Promise<any>{
        let res=await this.utils.ToolsProviderApi.clientQueryVideo(this.dataObj.otherParams.queryParam);
        this.dataObj.otherParams.videoTypeData=[];
        res.videoTypeBeanList.forEach((item:any,index:number)=>{
            this.dataObj.otherParams.videoTypeData.push({value:item.id,label:item.name,item:item});
        })
        this.dataObj.otherParams.videoData=res.videoBeanList;
        Object.assign(this.dataObj.otherParams.videoBakData,this.dataObj.otherParams.videoData);
    }
    public doFiler():void{
        if(this.dataObj.otherParams.queryParam.filterKey || this.dataObj.otherParams.queryParam.videoTypeId){
            let searchResult=[];
            for(let i=0;i<this.dataObj.otherParams.videoBakData.length;i++){
                let name=this.dataObj.otherParams.videoBakData[i]['name'];
                let videoTypeId=this.dataObj.otherParams.videoBakData[i]['videoTypeId'];
                if(this.dataObj.otherParams.queryParam.filterKey && this.dataObj.otherParams.queryParam.videoTypeId){
                    if(name.indexOf(this.dataObj.otherParams.queryParam.filterKey)!=-1 && this.dataObj.otherParams.queryParam.videoTypeId==videoTypeId)searchResult.push(this.dataObj.otherParams.videoTypeBakData[i]);
                } else if(this.dataObj.otherParams.queryParam.filterKey){
                    if(name.indexOf(this.dataObj.otherParams.queryParam.filterKey)!=-1)searchResult.push(this.dataObj.otherParams.videoBakData[i]);
                }else if(this.dataObj.otherParams.queryParam.videoTypeId){
                    if(this.dataObj.otherParams.queryParam.videoTypeId==videoTypeId)searchResult.push(this.dataObj.otherParams.videoBakData[i]);
                }
            }
            this.dataObj.otherParams.videoData=searchResult;
        }else{//如果是清空了查询条件，则展示所有的
            this.dataObj.otherParams.videoData=this.dataObj.otherParams.videoBakData;
        }
    }
}