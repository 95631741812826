import {
    ref,
    reactive,
    toRefs,
    defineComponent,
    onBeforeMount,
    onMounted,
    getCurrentInstance,
    computed,
    nextTick
} from 'vue';
import ClientRepairVideosUtil ,{IClientRepairVideosDataObj} from './clientRepairVideosUtil';
export default defineComponent({
    name:'personCenter',
    beforeRouteEnter(to,from,next){
        // if(sessionStorage.getItem('userInfo')){
        //     next();
        // }else{
        //     next('/clientLogin');
        // }
        next();
    },
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IClientRepairVideosDataObj=reactive<IClientRepairVideosDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:{
                videoTypeData:[],//视频类别集合
                videoData:[],//视频集合
                videoBakData:[],//视频集合备份
                queryParam:{
                    videoTypeId:'',//视频类别Id
                    filterKey:'',//视频名称
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ClientRepairVideosUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.clientQueryVideo();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'videoType'==params.comboId){
                    return dataObj.otherParams.videoTypeData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:string,selectId:string)=>{
            if('videoType'==selectId){
                dataObj.utilInst.doFiler();
            }
        }
        const searchHandler=()=>{
            dataObj.utilInst.doFiler();
        }
        const play=async (url:string)=>{
            await proxy.$router.replace({path:'/videoPlay',query:{url:url}});
        }
        const backHandler=async ()=>{
            await proxy.$router.replace({path:'/clientRepairChoose'});
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,searchHandler,play,backHandler
         
        }
    }
});